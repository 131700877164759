
@import '../../scss/variables/colors.module.scss';

@font-face {
  font-family: 'SF Pro Display';
  src: url(../../assets/fonts/SFProText-Medium.ttf);
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  font-family: 'SF Pro Display' !important;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  // color: $textcolor;
}

a,
span,
p,
b,
h1,
h2,
h3,
h4,
h5 {
  color: 'black';
}

h1 {
  font-size: 48px;
}

h2 {
  font-weight: 600;
  font-size: 28px;

}

a {
  text-decoration: none;
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
