.no_login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 400px;
    margin: 0 auto;
    text-align: center;
}
.login_link{
    margin-top: 20px;
}