.category {
    &_card {
        width: 94%;
        &_block {
            padding: 100px 85px;
            border-radius: 6.974px;
            background: #F2F3F7;
            height: 530px;
           

            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }

        &_title {
            color: #000;
            font-family: SF Pro Display;
            font-size: 29px;
            font-weight: 500;
            line-height: 100%;
            margin-top: 20px;
        }

        &_price {
            color: #71767E;
            font-family: SF Pro Display;
            font-size: 18px;
            font-weight: 400;
            line-height: 140%;
        }
    }
}