.footer {
    padding: 24px 50px 0px;
    background-color: black;
    color: white;

    .wrapper {
        // max-width: 1722px;
        margin: 0 auto;
        padding: 10px 50px;
        display: flex;
        justify-content: space-between;

    }

    &_left {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &_navigation {
        width: 59%;
        display: flex;
        gap: 30px;

        h1 {
            font-size: 1.2rem;
        }

        p {
            color: #71767E;
            font-size: 1.1rem;
        }


    }

    .title {
        font-size: 1.7rem;
    }

    h2 {
        font-size: 16px;
    }

    &_form {
        display: flex;
        align-items: center;
        gap: 10px;

        .button {
            height: fit-content;
            padding: 15px 30px;
            border-radius: 4px;
            color: white;
            background: #282828;
            font-size: 16px;
            border: none;
        }

        .input {
            min-width: 300px !important;
            border: 1px solid #71767E;
            padding: 15px 15px;
        }
    }


    &_bottom {
        // max-width: 1722px;
        margin: 0 auto;
        padding: 80px 24px 20px;
        gap: 20px;

        &_SM {
            margin-bottom: 30px;
            display: flex;
            gap: 30px;
        }
    }

    .flex {
        color: #71767E;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .flex_justify {
        color: #71767E;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}



@media (max-width: 1399px) {
    .footer {
        .wrapper {
            flex-direction: column;
        }

        &_left {
            width: 100%;
        }

        &_navigation {
            width: 100%;
            justify-content: space-between;
        }

        &_form {
            .input {
                width: 70vw;
            }
           
        }
    }
}

@media (max-width: 991px) {
    .footer {
        
        &_navigation {
            width: 100%;
            justify-content: space-between;
        }

        &_form {
            .input {
                width: 65vw;
            }
           
        }
    }
}

@media (max-width: 767px) {
    .footer {
        
        &_navigation {
            width: 100%;
            justify-content: space-between;
        }

        &_form {
            .input {
                width: 50vw;
            }
           
        }
    }
}