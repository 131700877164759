// css всей страницы
.order {
    // max-width: 1722px;
    width: 100%;
    padding: 50px 100px;

    .conteiner {
        width: 100%;

        .flexConteiner {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .left {
                width: 48%;
                // css левой стороны

                h3 {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 110%;
                }

                .agreement {
                    width: 680px;
                    margin-bottom: 30px;
                }

                .button {
                    height: 60px;
                    color: #000;
                }

                .flexForm {
                    display: flex;
                    justify-content: space-between;

                    .item {
                        width: 50%;

                        .input1 {
                            margin-bottom: 8px;
                            width: 100%;
                            padding-right: 20px;
                        }

                        .input {
                            margin-bottom: 8px;
                        }

                        .link {
                            margin-bottom: 0;
                            width: 100%;
                            height: 100%;
                            text-align: end;
                            color: #000;
                        }

                        .linkButton {
                            padding-right: 0;

                        }

                        p {
                            color: rgba(0, 0, 0, 0.40);
                        }

                        .radio {
                            width: 100%;
                            height: 22px;
                            margin-bottom: 14px;
                        }
                    }

                    .item:first-child {
                        width: 50%;

                        .input {
                            margin-bottom: 8px;
                            margin-right: 20px;
                        }
                    }
                }
            }



            // конец левой части

            .right {
                width: 48%;

                h3 {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 110%;
                }

                .flexConteiner {
                    width: 100%;
                    margin-top: 24px;
                    border-top: 1px solid rgb(220, 220, 220);
                    padding-top: 30px;


                    .imgProduct {
                        width: 25%;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .title {
                        width: 40%;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 110%;
                        margin-bottom: 16px;

                        span {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 110%;
                            color: rgba(0, 0, 0, 0.40);
                            margin-bottom: 16px;
                        }

                        p {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 110%;
                            margin-top: 16px;
                        }
                    }

                    .price {
                        width: 20%;

                        h2 {
                            color: red;
                        }
                    }

                    .orderSum {
                        list-style: none;
                        width: 100%;

                        div {
                            color: #000;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: 140%;
                            margin-bottom: 20px;
                        }

                        .itog {
                            font-size: 25px;
                            font-weight: 850;
                            margin-top: 15px;
                        }

                        li {
                            display: flex;
                            justify-content: space-between;
                        }

                        li div:nth-child(2) {
                            flex: 1 0;
                            border-bottom: 1px dotted #000;
                            height: 1em;
                            margin: 0 .4em;
                        }
                    }


                }
            }



        }
    }
}