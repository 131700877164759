.container {
    // max-width: 1722px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    padding: 0 100px;
    padding-top: 50px;
    padding-bottom: 130px;

    .cont {
        width: 100%;
        height: 489px;
        border-radius: 8px;
        background: #F2F3F7;
        text-align: center;

        .log {
            padding-top: 40px;
            padding-bottom: 71px;
        }

        .title {
            margin: 0 auto;
            width: 677px;
            color: #131B22;
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            padding-bottom: 35px;
        }

        .input {
            width: 350px;
            height: 60px;
            border-radius: 5px;
            margin-right: 10px;
            font-size: 17px;
            font-style: normal;
            line-height: 140%;
        }

        .button {
            width: 149px;
            height: 60px;
            border-radius: 4px;
            background: #F5C423;
        }

        .button:hover {
            background-color: #eeb004;
            color: #000;
        }

        .text {
            width: 370px;
            margin: 0 auto;
            font-size: 18px;
            font-weight: 400;
            line-height: 140%;
            color: #000;

            .link {
                height: 28px;
                margin-left: 8px;
                padding: 0px;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                color: #000;
                border-bottom: 2px solid;
                border-radius: 0;
                transition: 0.2s all;
            }

            .link:hover{
                color: #F5C423;
            }

            
        }

        .flexFrom{
            display: flex;
            justify-content: center;
        }
    }
}