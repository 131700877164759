.company__list{
    padding-bottom: 20px;
    .container{
        // max-width: 1722px;
        padding: 0 100px;
        margin: 0 auto;
        width: 100%;
        >div{
            max-width: 100%;
            padding: 40px;

        }
        img{
            width: 70%;
        }
    }
    &_item{
        padding: 55px 60px !important;
        position: relative;
        &:not(:last-child)::after {
            content: "";
            position: absolute;
            right: 0;
            top:50%;
            transform:translateY(-50%);
            width: 1px;
            height: 50%;
            background: #DCDCDC; 
          }
    }     

    
}
