.container{
    margin: 0 auto; 
    width: 100%;    
    padding: 20px 100px;
    font-style: normal;
    font-weight: 400;
    .cart{
        &__empty{
            padding: 100px;
            >h2{
                font-size: 34px;
                font-weight: 600;
            }
            >p{
                font-size: 22px;
            }
            >a{
                color: #005BFF;
                font-size: 18px;
            }
        }
        &__items{
            width: 100%;
        }
        &__top{
            &1{
                >h1{
                    font-size: 38px;
                    font-weight: 700;
                }
                >span{
                    color: rgba(0, 0, 0, 0.40);
                    font-size: 16px;
                }
            }
            &2{
                >.delete__btn{
                    font-size: 16px;
                    >span{
                        cursor: pointer;
                        font-size: 20px;
                        transition: color .5s;
                        color: #9E9B98;
                        &:hover{
                            color: #000;
                        }
                    }
                }
                >label{
                    >span{
                        font-size: 18px;
                        >span{
                            width: 20px;
                            height: 20px;
                            &::after{
                                width: 17px;   
                                height: 17px;    
                                transform: translate(-50%,-50%);       
                                content: url('../../assets/icon/check_bl.svg');
                                border: none;
                                inset-inline-start: none;
                                display: block;
                            }
                        }
                        input{
                            line-height: normal;
                            box-sizing: border-box;
                        }
                    }
                    
                }
            }
        }
        &__bottom{
            padding: 30px 0;
        }
        &__form{
            width: 560px;
            border-radius: 8px;
            border: 1px solid #DCDCDC;
            padding: 20px;
            >h2{
                font-size: 29px;
                font-style: normal;
                font-weight: 600;
            }
            >.form_data{
                width: 100%;
                gap: 20px;
                >div{
                    width: 100%;
                    gap: 10px;
                    justify-content: space-between;
                    align-items: last baseline;
                    &:last-child{
                        margin-top: 20px;
                    }
                    >hr{
                        width: 100%;
                        border-top: dotted 1px;
                        opacity: .4;
                    }
                    >span{
                        width: 100%;
                        max-width: fit-content;
                        font-size: 16px;
                        font-weight: 500;
                        &:last-child{
                            font-weight: 700;
                            font-size: 22px;
                        }
                    }
                }
            }
            >button{
                width: 100%;
                padding: 15px 0  !important;
                height: fit-content;
                border-radius: 4px;
                background: #F5C423;
                margin-top: 20px;
                color: #000;
                &:hover{
                    background-color: transparent;
                }
            }
        }
        
    }
}
